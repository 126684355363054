<template>
  <TransitionRoot as="template" :show="generalStore.isWishlistDrawerOpen">
    <Dialog
      ref="dialog"
      as="div"
      class="relative z-[999]"
      @close="closeWithDelay"
    >
      <div
        class="fixed inset-0 top-[calc(var(--header-height)+var(--discount-compaign-visible-height))] overflow-hidden"
      >
        <div
          class="absolute inset-0 top-[calc(var(--header-height)+var(--discount-compaign-visible-height))] overflow-hidden"
        >
          <div
            class="pointer-events-none fixed bottom-0 right-0 top-[calc(var(--header-height)+var(--discount-compaign-visible-height))] flex max-w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-250"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-250"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
              @after-enter="onAfterEnter"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen md:max-w-[494px]"
              >
                <div
                  class="flex h-full flex-col overflow-hidden bg-white shadow-xl"
                >
                  <div
                    class="flex items-center justify-between border-b border-b-gray-60 px-4 py-6"
                  >
                    <DialogTitle class="text-sm uppercase text-gray-90">
                      {{ $t("yourWishlist") }}
                      {{ wishlistItemCount }}
                    </DialogTitle>
                    <UiButton
                      variant-type="icon"
                      variant="clear"
                      size="xs"
                      :aria-label="
                        $t('ariaLabelClose', { title: $t('yourWishlist') })
                      "
                      @click="generalStore.isWishlistDrawerOpen = false"
                    >
                      <UiIcon name="close" class="size-6 text-primary" />
                    </UiButton>
                  </div>

                  <div
                    class="styled-scrollbars relative flex-1 overflow-auto p-4"
                  >
                    <div
                      v-if="!isEmpty(wishlistBadge)"
                      class="grid grid-cols-2 gap-x-4 gap-y-5"
                    >
                      <CommonProductCardPlp
                        v-for="(product, idx) in wishlistBadge"
                        :key="product.sku"
                        :product="
                          {
                            ...product,
                            base_cost: product.cost as number,
                            images: product.images.map((img: string) => ({
                              url: img,
                            })),
                            default_sku: product.sku,
                          } as CatalogData
                        "
                        :product-index="idx"
                        :plp-type="plpTypes.default"
                      />
                    </div>
                    <div
                      v-else
                      class="pt-6 text-center text-xl text-gray-elements"
                    >
                      {{ $t("noItemsInWishlist") }}
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { onClickOutside } from "@vueuse/core";
import { useGeneralStore } from "@/stores/general";
import { plpTypes } from "~/configs";
import { CatalogData } from "~/types/api/catalog.types";

const generalStore = useGeneralStore();
const { wishlistBadge } = useWishlist();
const dialog = ref(null);

const { viewWishListEvent } = useEvents();

onClickOutside(dialog, () => {
  closeWithDelay();
});

watch(
  () => generalStore.isWishlistDrawerOpen,
  (val) => {
    if (val && wishlistBadge.value?.length) {
      viewWishListEvent(wishlistBadge.value);
    }
    generalStore.systemOverlay = val;
  },
);

const wishlistItemCount = computed(() => {
  return wishlistBadge.value.length ? `(${wishlistBadge.value.length})` : "";
});

function onAfterEnter() {
  setTimeout(() => {
    const inertEl = document.querySelector("[inert]");
    if (inertEl) {
      inertEl.removeAttribute("inert");
    }
  }, 0);
}

function closeWithDelay() {
  setTimeout(() => {
    generalStore.isWishlistDrawerOpen = false;
  }, 0);
}
</script>
